<template>
  <div id="app" class="app">
    <div
      class="spinner-wrapper d-flex justify-content-center align-items-center"
      v-if="isLoading"
    >
      <div class="loader"></div>
    </div>
    <div class="theatre" ref="theatre" v-if="!isLoading" :style="wrapperStyle">
      <div class="player-wrapper" :style="{ top: playerTop }">
        <before-event v-if="event.status == 'upcoming'" />
        <after-event v-if="event.status == 'completed'" />
        <live-player v-if="event.status == 'live'" :stream="streams[0]" />
        <player-bottom-bar />
        <question style="margin-top: 0px" />
      </div>
      <ResizeObserver @notify="updateSize" />
    </div>

    <b-modal
      id="dialogModal"
      :title="modalText.title"
      ok-only
      :ok-title="$t('okey')"
    >
      <p class="my-4">{{ modalText.message }}</p>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { auth, db } from '@/firebase';
import LivePlayer from '@/components/LivePlayer.vue';
import BeforeEvent from '@/components/BeforeEvent.vue';
import AfterEvent from '@/components/AfterEvent.vue';
import PlayerBottomBar from '@/components/PlayerBottomBar.vue';
import Question from '@/components/Question.vue';
import axios from 'axios';
import _ from 'lodash';
import { loadLanguageAsync } from '@/utils/i18n-setup';
import { mapActions, mapGetters } from 'vuex';
import { ResizeObserver } from 'vue-resize';

export default {
  components: {
    LivePlayer,
    Question,
    PlayerBottomBar,
    BeforeEvent,
    AfterEvent,
    ResizeObserver,
  },
  data() {
    return {
      modalText: {},
      isLoading: true,
      bgImgStyle: {},
      selectedLang: 'en',
      wrapperStyle: { backgroundImage: '' },
      streams: [],
      playerTop: '0px',
      stageBgUrl: null,
    };
  },
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
      appSettings: 'appsettings/current',
    }),
  },
  watch: {
    stageBgUrl(n, o) {
      if (n !== o) {
        this.wrapperStyle.backgroundImage = `url('${n}')`;
      }
    },
  },
  methods: {
    ...mapActions({
      setCurrentEvent: 'event/setCurrent',
      setCurrentUser: 'user/setCurrent',
      setStreamList: 'stream/setList',
      setCurrentStream: 'stream/setCurrent',
      getAppSettings: 'appsettings/get',
    }),
    async signInWithMagnetToken() {
      const data = await axios({
        method: 'GET',
        url: `${process.env.VUE_APP_BASE_URL}/registration/me`,
        headers: { Authorization: `Bearer ${this.$route.query.t}` },
      });
      return data;
    },
    async signInFromLocalStorage() {
      const token = localStorage.getItem(this.$route.params.slug);
      const data = await axios({
        method: 'GET',
        url: `${process.env.VUE_APP_BASE_URL}/registration/me`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    },
    updateSize(size) {
      if (size.width > '768') {
        this.sidebarOpen = true;
      }
      this.playerTop = `${size.width / (1.78 * 8.92)}px`;
    },
    updateSelectedLang() {
      loadLanguageAsync(this.selectedLang);
      this.$moment.locale(this.selectedLang);
    },
  },
  async mounted() {
    let data = null;

    // get app Settings
    await this.getAppSettings();

    // Fetch event and registration data
    try {
      if (this.$route.query.t) {
        data = await this.signInWithMagnetToken();
      } else {
        data = await this.signInFromLocalStorage();
      }

      this.setCurrentUser(data.data.registration);
      this.setCurrentEvent(data.data.event);
      this.setStreamList(data.data.streams);
      this.setCurrentStream(data.data.streams[0]);
    } catch (error) {
      console.log(error);
      this.modalText = {
        title: 'Error!',
        message: this.$t('registration_required'),
      };
      this.$bvModal.show('dialogModal');
      if (_.has(this.$route, 'params.slug')) {
        window.location = `${this.appSettings.general.landingUrl}/${this.$route.params.slug}`;
      }

      return false;
    }

    // signin via firebase
    try {
      await auth().signInWithCustomToken(this.user.fbToken);
    } catch (err) {
      this.modalText = {
        title: 'Error!',
        message: 'Invalid access.',
      };
      this.$bvModal.show('dialogModal');
      return false;
    }

    // Put token to the localStorage for further page refresh
    localStorage.setItem(this.event._id, this.user.token);

    // if signin completed, remove the token from address bar
    this.$router.push(`/${this.event._id}`).catch(() => {});

    // listen auth changes and refresh the token if necessary
    auth().onAuthStateChanged(async (user) => {
      if (!user) {
        const {
          data: { fbToken },
        } = await axios({
          method: 'GET',
          url: `${process.env.VUE_APP_BASE_URL}/refresh`,
          headers: { Authorization: `Bearer ${this.user.token}` },
        });

        await auth().signInWithCustomToken(fbToken);
      }
    });

    // init selected language
    loadLanguageAsync(this.event.interfaceLang);
    this.selectedLang = this.event.interfaceLang;
    this.$moment.locale(this.selectedLang);

    // listen event status updates
    db.collection('event')
      .doc(this.event._id)
      .onSnapshot({}, (doc) => {
        this.setCurrentEvent(Object.assign(this.event, doc.data()));
        this.isLoading = false;
        this.stageBgUrl = this.event.pageSettings.stageBgUrl;
        this.$nextTick(() => {
          this.updateSize({
            width: this.$refs.theatre.clientWidth,
            height: this.$refs.theatre.clientHeight,
          });
        });
      });

    db.collection('event')
      .doc(this.event._id)
      .collection('settings')
      .doc('pageSettings')
      .onSnapshot({}, (doc) => {
        const settings = doc.data();
        this.stageBgUrl = settings.stageBgUrl;
      });

    return true;
  },
};
</script>
<style lang="scss">
html,
body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000 !important;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.main-container {
  max-width: 840px !important;
  min-height: calc(100vh - 209px) !important;
}

.spinner-wrapper {
  width: 100vw;
  height: calc(100vh - 96px);
}

.loader,
.loader:after {
  border-radius: 100%;
  width: 64px;
  height: 64px;
}
.loader {
  position: relative;
  border-top: 4px solid rgba(0, 0, 0, 0.1);
  border-right: 4px solid rgba(0, 0, 0, 0.1);
  border-bottom: 4px solid #007bff;
  border-left: 4px solid #007bff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.6s infinite linear;
  animation: load8 0.6s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.theatre {
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
  justify-content: center;
  background-position-x: center;

  width: 100vw;
  max-width: 1440px;
  height: calc(100vw / 1.78);
  max-height: calc(1440px / 1.78);
}
.player-wrapper {
  position: relative;
  width: 47.6%;
  left: 2px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.footer {
  width: 100%;
  background-color: #ececec;
  padding: 0px 16px 1px;
  margin-top: 32px;
  .lang-select-wrapper {
    width: 150px;
  }
  .col-form-label-sm {
    line-height: 0;
    z-index: 100;
    position: relative;
    font-size: 10px;
    left: 13px;
    color: #007bff;
    top: 25px;
  }
  #page-lang {
    padding: 0.975rem 1.75rem 0.275rem 0.75rem;
    height: auto;
  }
}
</style>
